/**
 * Applies the autosize function on every textarea
 */
const autosizeTextareas = () => {
    const textareas = document.getElementsByTagName('textarea');

    for (let index = 0; index < textareas.length; index++) {
        autosize(textareas[index]);
    }
}

const displayPriceByCountry = (country) => {
    // Prices everywhere other than US are canadian
    let prices = $('.price-cad');
    let priceWord = 'CAD';

    if(country == 'US'){
      prices = $('.price-usd');
      priceWord = 'USD';
    }
    if(country !== 'CA' && country !== 'US'){
      prices = $('.price-usd');
    }

    for (let index = 0; index < prices.length; index++) {
        let text = $(prices[index]).text();

        text = text.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        text += ' ' + priceWord;

        $(prices[index]).text(text);
        $(prices[index]).show();
    }
}

const addPremium = () => {
    var xhr = new XMLHttpRequest();

    xhr.open("GET","https://ipapi.co/json/", true);

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                displayPriceByCountry(data.country);
            }
        }
    };

    xhr.onerror = function(e) {
        displayPriceByCountry('CA');
    };

    xhr.send();
}

const initializeMaps = () => {
    // Since this runs on every page, catch the error if element does not exist
    try {
        $('#usa-map').JSMaps({
            map: 'usa',
            onReady: function() {
                $("section[id='usa-map-accordion'] h3").on('click', function () {
                    $('#usa-map').trigger('stateClick', $(this).attr('state'));
                });
            },
            // When a map state is clicked, this event handler gets raised
            onStateClick: function (data) {
                // If the state is already active, don't reselect it
                if ($("section[id='usa-map-accordion'] h3[state='" + data.name + "']").hasClass('active')) {
                    return;
                }

                const states = [
                    'Florida',
                    'Oklahoma',
                    'Michigan',
                    'Texas',
                    'New York'
                ];

                if (states.findIndex(state => state === data.name) >= 0){
                    selectState(data.name);
                }
            }
        });
    } catch (e) {
        // Do nothing
    }
}

const selectState = (stateName) => {
    resetStates();

    // Show the selected state
    $("section[id='usa-map-accordion'] section[state='" + stateName + "'] p").slideDown();
    $("section[id='usa-map-accordion'] h3[state='" + stateName + "']").addClass('active');
    $("section[id='usa-map-accordion'] h3[state='" + stateName + "'] span:nth-child(2)").html('-')

    // If on phone or tablet, make state click scroll into view
    if(window.matchMedia('(max-width: 768px)')) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("section[id='usa-map-accordion'] h3[state='" + stateName + "']").offset().top
        }, 500);
    }
}

const resetStates = () => {
    $("section[id='usa-map-accordion'] p").slideUp();
    $("section[id='usa-map-accordion'] h3").removeClass('active');
    $("section[id='usa-map-accordion'] h3 span:nth-child(2)").html('+');
}

const truncateText = (className) => {
  const elements = document.getElementsByClassName(className);

  // Adds ellipses at end of text of each textbox with given className
  for(index = 0; index < elements.length; index++){
    const element = elements[index];
    const wordArray = element.innerHTML.split(' ');

    while(element.scrollHeight > element.offsetHeight) {
      wordArray.pop();
      element.innerHTML = wordArray.join(' ') + '...';
    }
  }
}
