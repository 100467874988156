const surgicalFieldElement = $('input[name=surgical_history]')
const surgicalHistoryItems = [];
let surgeryHistoryElement = null;
let surgicalHistoryDeleteElement = null;
let surgicalHistoryInputElements = null;

/**
 * Sets the surgical history fields
 */
function setSurgicalHistory() {
    surgeryHistoryElement = $('.surgical-history-item');

    surgicalHistoryInputElements = surgeryHistoryElement.children()[0];
    surgicalHistoryDeleteElement = surgeryHistoryElement.children()[1];

    surgeryHistoryElement.html(null);

    addSurgicalHistoryField();
}

/**
 * Appends a surgical history field to the DOM
 */
function addSurgicalHistoryField() {
    surgicalHistoryItems.push({
        procedure: '',
        procedure_date: '',
        procedure_complications: '',
    });

    const index = surgicalHistoryItems.length - 1;

    const inputElements = $(surgicalHistoryInputElements).clone();
    const procedureElement = inputElements.find('input[name=procedure]');
    const dateElement = inputElements.find('input[name=procedure_date]');
    const complicationsElement = inputElements.find('input[name=procedure_complications]');
    
    const deleteElement = $(surgicalHistoryDeleteElement).clone();
    const deleteElementBox = deleteElement.find('.surgical-history-delete');

    procedureElement.attr('onchange', 'changeSurgicalHistoryField(this)');
    procedureElement.attr('index', index);
    dateElement.attr('onchange', 'changeSurgicalHistoryField(this)');
    dateElement.attr('index', index);
    complicationsElement.attr('onchange', 'changeSurgicalHistoryField(this)');
    complicationsElement.attr('index', index);

    deleteElementBox.attr('index', index);
    deleteElementBox.attr('onclick', 'deleteSurgicalHistoryField(this)')

    inputElements.appendTo(surgeryHistoryElement);
    deleteElement.appendTo(surgeryHistoryElement);
}

/**
 * Handles the change of a surgical history field
 */
function changeSurgicalHistoryField(element) {
    const formElement = $(element);
    const field = formElement.attr('name');
    const index = formElement.attr('index');

    surgicalHistoryItems[index][field] = formElement.val();
    updateFormField();
}

/**
 * Deletes a surgical history field
 */
function deleteSurgicalHistoryField(element) {
    const formElement = $(element);
    const position = formElement.attr('index');

    // First delete the position
    surgicalHistoryItems.splice(position, 1);
    $(surgeryHistoryElement.find(`input[name=procedure][index=${position}]`)).parent().parent().parent().parent().parent().remove();
    $(surgeryHistoryElement.find(`.surgical-history-delete[index=${position}]`)).parent().remove();

    // Adjust the element indexes
    const procedureElements = surgeryHistoryElement.find('input[name=procedure]');
    const dateElements = surgeryHistoryElement.find('input[name=procedure_date]');
    const complicationsElements = surgeryHistoryElement.find('input[name=procedure_complications]');
    const deleteElements = surgeryHistoryElement.find('.surgical-history-delete');

    for (let index = 0; index < surgicalHistoryItems.length; index++) {
        $(procedureElements[index]).attr('index', index);
        $(dateElements[index]).attr('index', index);
        $(complicationsElements[index]).attr('index', index);
        $(deleteElements[index]).attr('index', index);
    }

    updateFormField();
}

/**
 * Because the finished product will be an input field, we need to update
 * the value of it
 */
function updateFormField() {
    let value = '';

    surgicalHistoryItems.forEach(item => {
        if (item.procedure == '' && item.procedure_date == '' && item.procedure_complications == '') {
            return true;
        }

        value += (item.procedure ? item.procedure : 'Not specified') + ' | ';
        value += (item.procedure_date ? item.procedure_date : 'Not specified') + ' | ';
        value += (item.procedure_complications ? item.procedure_complications : 'Not specified') + '\n\n';
    });

    surgicalFieldElement.val(value);
}