const form = $('.questionnaire-form');
const requiredFields = {
    general_information: [
        'first_name',
        'last_name',
        'email_address',
        'confirm_email_address',
        'birth_date',
        'home_phone',
        'communication_type',
        'surgery_planned',
        'street_address',
        'postal_code',
        'city',
        'province',
        'country',
        'hear_about',
    ],
    physical_information: [
        'weight',
        'height',
        'bmi',
        'gender',
        'allergies_answer',
        'steroid_cortisone_answer',
        'medications_answer',
        'anticoagulants_answer',
        'alcohol_answer',
        'smoker_answer',
        'drugs_answer',
        'occupation',
        'cardiovascular_answer',
        'respiratory_answer',
        'digestive_answer',
        'endocrine_answer',
        'neurological_answer',
        'hematological_answer',
        'urological_answer',
        'musculoskeletal_answer',
        'dental_oral_answer',
        'deficiencies_impairments_answer',
        'snoring_answer',
        'fatigue_answer',
        'breathing_answer',
        'blood_pressure_answer',
        'neck_circumference_answer',
    ],
    primary_contact: [
        'contact_first_name',
        'contact_last_name',
        'contact_phone_number',
        'contact_relationship',
    ],
    medical_history: [
        'adverse_reactions_answer',
    ],
    medical_insurance: [
        'resident_of_quebec'
    ],
    risks: []
};

// store depended require fields together
// ex. if is resident of quebec, ramq number is required
const linkedFields = {
    resident_of_quebec: {
        true: [
            'ramq_number'
        ],
        false: []
    }
};


const matchFields = {
    general_information: [[ 'email_address', 'confirm_email_address' ]],
    physical_information: [],
    physical_information: [],
    primary_contact: [],
    medical_history: [],
    medical_insurance: [],
    risks: []
};

function removeAllErrors() {
    const formFields = form.find('input, textarea, select');

    formFields.each((index, field) => {
        $(field).parent().removeClass('has-error');
        $(field).parent().parent().removeClass('has-error');
    });
}

function validateStep(step) {
    let hasErrors = false;

    removeAllErrors();

    // Looping through required fields
    requiredFields[step].forEach(field => {
        const formField = form.find(`[name=${field}]`);

        if (formField.length === 1 && !formField.val()) {
            formField.parent().addClass('has-error');

            hasErrors = true;
        } else if (formField.length > 1) {
            let checked = 0;
            let optionsRequired = false;

            formField.each((index, element) => {
                if (element.checked) {
                    checked++;

                    if ($(element).val() === 'true') {
                        optionsRequired = true;
                    }
                }
            });

            if (checked === 0) {
                formField.parent().parent().addClass('has-error');

                hasErrors = true;
            } else if (optionsRequired) {
                const checkboxElements = formField.parent().parent().find('input[type=checkbox]');

                if (checkboxElements.length > 0) {
                    let selected = 0;

                    checkboxElements.each((index, element) => {
                        if (element.checked) {
                            selected++;
                        }
                    });

                    if (selected === 0) {
                        formField.parent().parent().addClass('has-error');

                        hasErrors = true;
                    }
                } else {
                    const fieldName = field.replace('_answer', '');
                    const answerField = formField.parent().parent().find(`[name=${fieldName}]`);

                    if (!$(answerField).val()) {
                        answerField.parent().parent().addClass('has-error');

                        hasErrors = true;
                    }
                }
            }
        }

        // check if two fields are linked to be required
        // ex. if is resident of quebec, ramq number is required
        if (field in linkedFields) {
            let requiredFields = [];
            if (formField.val() === 'Yes') {
                requiredFields = linkedFields[field].true;
            } else if (formField.val() === 'No') {
                requiredFields = linkedFields[field].false;
            }

             requiredFields.forEach(field => {
                const formField = form.find(`[name=${field}]`);
                if (formField.length === 1 && !formField.val()) {
                    formField.parent().addClass('has-error');
                    hasErrors = true;
                }
            });
        }
    });

    // Checking for field matches
    matchFields[step].forEach(fields => {
        const formField1 = form.find(`[name=${fields[0]}]`);
        const formField2 = form.find(`[name=${fields[1]}]`);

        if (formField1.val() !== formField2.val()) {
            formField1.parent().addClass('has-error');
            formField2.parent().addClass('has-error');

            hasErrors = true;
        }
    });

    if (hasErrors) {
        const firstError = $($(`#${step}_form`).find('.has-error')[0]);

        $([document.documentElement, document.body]).animate({
            scrollTop: firstError.offset().top - 70
        }, 500);
    }

    return hasErrors;
}
