let mobileMenuActive = false;
let activeSubmenu = null;

const mobileMenu = document.getElementById('mobile-nav');

const mobileMenuCloseArrow = document.getElementById('mobile-menu-close-arrow');
const mobileMenuCloseText = document.getElementById('mobile-menu-close-text');
const mobileMenuCloseSubname = document.getElementById('mobile-menu-subname');

const mobileMainMenu = document.getElementById('mobile-nav-main');
const mobileSubmenus = document.getElementsByClassName('mobile-nav-submenu');
let activeSubmenuContent = null;

function toggleMobileMenu () {
    if (activeSubmenu) {
        activeSubmenu = null;
        mobileMenuCloseArrow.style.display = 'none';
        mobileMenuCloseText.style.display = 'inline';

        mobileMenuCloseSubname.style.display = 'none';
        mobileMenuCloseSubname.innerHTML = '';

        mobileMainMenu.style.display = 'block';
        activeSubmenuContent.style.display = 'none';
        activeSubmenuContent = null;

        return;
    }

    mobileMenuActive = !mobileMenuActive;

    if (mobileMenuActive) {
        mobileMenu.style.left = 0;
    } else {
        mobileMenu.style.left = null;
    }
}

function activateSubmenu (name) {
    activeSubmenu = name;
    mobileMenuCloseArrow.style.display = 'inline';
    mobileMenuCloseText.style.display = 'none';

    mobileMenuCloseSubname.style.display = 'inline';
    mobileMenuCloseSubname.innerHTML = activeSubmenu;

    mobileMainMenu.style.display = 'none';

    for (let index = 0; index < mobileSubmenus.length; index++) {
        if (mobileSubmenus[index].getAttribute('name') == activeSubmenu) {
            activeSubmenuContent = mobileSubmenus[index];
            activeSubmenuContent.style.display = 'block';
        }
    }
}
