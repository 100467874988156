const steps = [
    'general_information',
    'physical_information',
    'primary_contact',
    'medical_history',
    'medical_insurance',
    'risks',
    'review'
];
const sidebarItems = $('.sidebar-item');
const formContents = $('.form-content');

let completedSteps = [];
let activeStep = steps[0];
let currentStep = steps[0];

// BMI Calculator
let imperialSystemActive = true;
let weight = 0;
let heightMeters = 0;
let heightInches = 0;

/**
 * Inits the sidebar and content
 */
function questionnaireInit() {
    var path = window.location.pathname.split('/');

    if (path[path.length - 1] !== 'questionnaire') {
        return;
    }

    setCurrentStep();
    setActiveStep();
    setCompletedSteps();
    setMeasurementSystem();
    setSurgicalHistory();
}

/**
 * Unline setActiveStep, this simply indicates which step the user needs to complete
 */
function setCurrentStep() {
    sidebarItems.each((index, sidebarItem) => {
        $(sidebarItem).removeClass('current');
    });

    $(`#${currentStep}`).addClass('current');
}

/**
 * Sets the active steps by adding / removing .active as class
 */
function setActiveStep() {
    sidebarItems.each((index, sidebarItem) => {
        $(sidebarItem).removeClass('active');
    });

    formContents.each((index, formContent) => {
        $(formContent).hide();
    });

    $(`#${activeStep}`).addClass('active');
    $(`#${activeStep}_form`).show();
    
    if (activeStep === steps[0]) {
        $('div#back').addClass('inactive');
    } else {
        $('div#back').removeClass('inactive');
    }

    $([document.documentElement, document.body]).animate({
        scrollTop: 0
    }, 300);

    setBottomNavigation();
}

/**
 * Sets the state of the bottom navigation
 */
function setBottomNavigation() {
    const activeStepIndex = steps.findIndex((step) => {
        return step === activeStep
    });
    const currentStepIndex = steps.findIndex((step) => {
        return step === currentStep
    });
    const footerStepNames = $('div#step-name').find('div');

    footerStepNames.each((index, element) => {
        const titleElement = $(element);

        if (titleElement.hasClass(activeStep)) {
            titleElement.show();
        } else {
            titleElement.hide();
        }
    });
    $('span#step-number-value').html(`${activeStepIndex + 1} / ${steps.length}`);

    if (activeStep === steps[steps.length - 1]) {
        $('div#next').css('display', 'none');
        $('div#submit').css('display', 'flex');
    } else {
        $('div#next').css('display', 'flex');
        $('div#submit').css('display', 'none');
    }

    $('div.progress-bar div.progress').css('max-width', `${((completedSteps.length + 1) / steps.length) * 100}%`)
}

/**
 * Sets the completed steps by adding .completed as class
 */
function setCompletedSteps() {
    completedSteps.forEach(completedStep => {
        $(`#${completedStep}`).addClass('completed');
    });
}

/**
 * Shows and hides the elements on the page based on the measurement system
 */
function setMeasurementSystem() {
    const imperialElements = $('.imperial');
    const metricElements = $('.metric');
    
    imperialElements.each((index, element) => {
        if (imperialSystemActive) {
            $(element).show();
        } else {
            $(element).hide();
        }
    });

    metricElements.each((index, element) => {
        if (!imperialSystemActive) {
            $(element).show();
        } else {
            $(element).hide();
        }
    });
}

/**
 * Activates a specific step
 */
function goToStep(step) {
    if (currentStep !== step && !completedSteps.includes(step)) {
        return;
    }

    if (step === 'review') {
        updateReviewValues();
    }

    if (step === 'medical_insurance') {
        renderInsuranceForm();
    }

    activeStep = step;
    setActiveStep();

    handleElectronicCommunicationsStep(step);
}

/**
 * When reaching the electronic communications step, we need to disable the next button by default.
 * Once the user agrees and goes next, we will no longer allow then to change their answer
 */
function handleElectronicCommunicationsStep(step) {
    if (currentStep === 'review') {
        $('input[name="electronic_communications_agreement"]').attr('disabled', true);

        return;
    }

    if (step === 'risks' && !$('input[name="electronic_communications_agreement"]').is(':checked')) {
        $('button.next-step').attr('disabled', true);
        $('div.next-step').addClass('inactive');
    } else {
        $('button.next-step').attr('disabled', false);
        $('div.next-step').removeClass('inactive');
    }
}

/**
 * This acts as a submit of the current step
 */
function goToNextStep() {
    if (activeStep === currentStep && validateStep(currentStep)) {
        return;
    } else if (validateStep(activeStep)) {
        return;
    }

    const activeStepIndex = steps.findIndex((step) => {
        return step === activeStep
    });

    const completedStepIndex = completedSteps.findIndex((step) => {
        return step === activeStep
    });

    if (completedStepIndex < 0) {
        completedSteps.push(activeStep);
    }
    
    currentStep = steps[activeStepIndex + 1];

    setCompletedSteps();
    setCurrentStep();
    goToStep(currentStep);
}

function goToPreviousStep() {
    const activeStepIndex = steps.findIndex((step) => {
        return step === activeStep
    });

    if (activeStepIndex === 0) {
        return;
    }

    goToStep(steps[activeStepIndex - 1]);
}

/**
 * Toggles the measurement system between metric and imperial
 */
function toggleMeasurementSystem() {
    imperialSystemActive = !imperialSystemActive;

    $('#height_field').val('');
    $('input[name=meters]').val('');
    $('input[name=feet]').val('');
    $('input[name=inches]').val('');

    heightInches = 0;
    heightMeters = 0;

    setMeasurementSystem();
    calculateBMI();
}

/**
 * Calculates the BMI based on weight and height
 */
function calculateBMI() {
    const isHeightSet = heightInches > 0 || heightMeters > 0
    let bmi = 0;


    if (imperialSystemActive) {
        bmi = weight / heightInches / heightInches * 703;
    } else {
        bmi = weight / heightMeters / heightMeters;
    }

    if (isHeightSet) {
        bmi = Math.round(bmi * 100) / 100;
    } else {
        bmi = 0;
    }

    $('#bmi_calculation').html(bmi);
    $('.bmi_calculation').val(bmi);
}

/**
 * Performs calculations when changing a measurement (weight / height)
 */
function onChangeMeasurement(element) {
    const formElement = $(element);

    const value = formElement.val();
    const name = formElement.attr('name');

    if (name === 'pounds_kilograms') {
        weight = value;
    } else if (name === 'meters') {
        heightMeters = value;
    } else if (name === 'feet') {
        heightInches = (value * 12) + (heightInches % 12);
    } else if (name === 'inches') {
        heightInches = (heightInches - heightInches % 12) + +value;
    }

    if (imperialSystemActive) {
        const tempInches = heightInches % 12;
        const tempFeet = (heightInches - (heightInches % 12)) / 12;

        $('#weight_field').val(`${weight} lbs.`);
        $('#height_field').val(`${tempFeet}' ${tempInches}"`);
    } else {
        $('#weight_field').val(`${weight} kg.`);
        $('#height_field').val(`${heightMeters} m`);
    }
    
    calculateBMI();
}

/**
 * Handles changes to a conditional field
 */
function onChangeConditional(element, formField) {
    const formElement = $(element);
    let formElementParent = formElement.parent().parent();

    // check is input of resident of quebec
    if (formField === 'resident_of_quebec') {
        renderInsuranceForm(formElement.val() === 'true');
    }

    if (formElement.attr('type') === 'checkbox') {
        formElementParent = formElementParent.parent();
    }

    const formCheckboxContainer = formElementParent.find('div.conditional');
    const formHiddenElement = formElementParent.find('input[type=hidden]');
    const formValueElement = formElementParent.find('input[type=text], textarea');

    if (formElement.attr('type') === 'radio' && formElement.val() === 'false') {
        formHiddenElement.val('No');
        formValueElement.val('No');
        formCheckboxContainer.slideUp(100);

        return;
    } else if (formElement.attr('type') === 'radio' && formElement.val() === 'true') {
        formHiddenElement.val('Yes');
        formValueElement.val('');
        formCheckboxContainer.slideDown(100);

        return;
    }

    if (formElement.attr('type') === 'checkbox') {
        const formOptions = formCheckboxContainer.find('input[type=checkbox]');
        let value = 'Yes. ';
        let numberOfItems = 0;

        formOptions.each((index, element) => {
            if (element.checked) {
                value += (numberOfItems > 0 ? ', ' : '') + $(element).val();

                numberOfItems++;
            }
        });

        formHiddenElement.val(value);
    }
}

function renderInsuranceForm(isFromQuebec) {
    if (isFromQuebec === undefined && $('input[name=resident_of_quebec]').val() !== '') {
        isFromQuebec = $('input[name=resident_of_quebec]').val() === 'Yes';
    }

    const insuranceProvider = $('input[name=insurance_provider]').parent().parent();
    const medicalInsurance = $('input[name=medical_insurance]').parent().parent();
    const ramqNumer = $('input[name=ramq_number]').parent().parent();

    if (isFromQuebec === undefined) {
        ramqNumer.hide();
        medicalInsurance.hide();
        insuranceProvider.hide();
    } else if (isFromQuebec) {
        insuranceProvider.hide();
        $('input[name=insurance_provider]').val(null);
        medicalInsurance.hide();
        $('input[name=medical_insurance]').val(null);
        ramqNumer.fadeIn(100);
    } else {
        ramqNumer.hide();
        $('input[name=ramq_number]').val(null);
        insuranceProvider.fadeIn(100);
        medicalInsurance.fadeIn(100);
    }
}

/**
 * Enables/Disables the submit button when the terms and conditions is toggled
 */
function toggleTermsAndConditions(element) {
    const formElement = $(element);

    if (formElement.is(':checked')) {
        $('button.submit').attr('disabled', false);
        $('div#submit').removeClass('inactive');
    } else {
        $('button.submit').attr('disabled', true);
        $('div#submit').addClass('inactive');
    }
}

/**
 * Enables/Disables the next button when the electronic communications checkbox is toggled
 */
function toggleElectronicCommunications(element) {
    const formElement = $(element);

    if (formElement.is(':checked')) {
        $('button.next-step').attr('disabled', false);
        $('div.next-step').removeClass('inactive');
    } else {
        $('button.next-step').attr('disabled', true);
        $('div.next-step').addClass('inactive');
    }
}

/**
 * Updates the values in the review section
 */
function updateReviewValues() {
    const formReviewFields = $('.form-review .form-field');

    formReviewFields.each((index, element) => {
        const valueField = $(element).find('div.value');
        const fieldName = valueField.attr('field');
        const radioValue = $(`input[name=${fieldName}]:checked`).val();

        if (radioValue) {
            valueField.html(radioValue);
        } else {
            valueField.html($(`input[name=${fieldName}], textarea[name=${fieldName}], select[name=${fieldName}]`).val());
        }
    });

    if ($('input[name=resident_of_quebec]').val() === 'Yes') {
        $("div[field='ramq_number']").parent().parent().show();
        $("div[field='insurance_provider']").parent().parent().hide();
        $("div[field='medical_insurance']").parent().parent().hide();
    } else {
        $("div[field='ramq_number']").parent().parent().hide();
        $("div[field='insurance_provider']").parent().parent().show();
        $("div[field='medical_insurance']").parent().parent().show();
    }
}

/**
 * Used to submit form manually from the bottom navigation
 */
function submitForm() {
    if ($('div#submit').hasClass('inactive')) {
        return;
    }

    $('input[name="electronic_communications_agreement"]').attr('disabled', false);

    $('form').submit();
}

questionnaireInit();